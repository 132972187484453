<template>
  <div class="row justify-content-center mt-5 mb-3" >
    <div class="col">
      <h2 :class="bootom_blue_line ? 'bootom-blue-line' : 'blue-line' " class="title text-center" v-if="title && title.length > 0"> {{ title }}</h2>
      <p class="h3 d-block text-center text-primary" v-if="subtitle" v-html="subtitle" />
    </div>
  </div>
</template>

<script>
export default {
name: "AppSection",
  props: {
    title: {
      required: true,
      type: String
    },
    bootom_blue_line:{
      type: Boolean,
      default: false
    },
    subtitle: {
      required: false,
      type: String,
      default: function(){
        return null
      }
    },
  }
}
</script>

<style scoped>

</style>